/* Match Lookup JS */
import React, { useState, useEffect, useRef } from "react";
import styles from "./MatchLookupPage.module.css";
import teamLogos from "../utils/teamLogos";

const MatchLookupPage = () => {
  const teams = [
    { name: "Adelaide", id: 1 },
    { name: "Brisbane Lions", id: 2 },
    { name: "Carlton", id: 3 },
    { name: "Collingwood", id: 4 },
    { name: "Essendon", id: 5 },
    { name: "Fremantle", id: 6 },
    { name: "Geelong", id: 7 },
    { name: "Gold Coast", id: 8 },
    { name: "Greater Western Sydney", id: 9 },
    { name: "Hawthorn", id: 10 },
    { name: "Melbourne", id: 11 },
    { name: "North Melbourne", id: 12 },
    { name: "Port Adelaide", id: 13 },
    { name: "Richmond", id: 14 },
    { name: "St Kilda", id: 15 },
    { name: "Sydney", id: 16 },
    { name: "West Coast", id: 17 },
    { name: "Western Bulldogs", id: 18 },
  ];

  const rounds = Array.from({ length: 29 }, (_, i) => `Round ${i}`);

  const [selectedRound, setSelectedRound] = useState("Select Round");
  const [selectedTeam, setSelectedTeam] = useState("Select Team");
  const [roundDropdownOpen, setRoundDropdownOpen] = useState(false);
  const [teamDropdownOpen, setTeamDropdownOpen] = useState(false);
  const [year, setYear] = useState("");
  const [gamesData, setGamesData] = useState(null);
  const [searchInitiated, setSearchInitiated] = useState(false); // New state for tracking search initiation

  const [isIncompleteChecked, setIsIncompleteChecked] = useState(false);
  const [isCompleteChecked, setIsCompleteChecked] = useState(false);
  const [isLiveChecked, setIsLiveChecked] = useState(false);
  const [isFinalChecked, setIsFinalChecked] = useState(false);
  const [isGrandFinalChecked, setIsGrandFinalChecked] = useState(false);

  const roundDropdownRef = useRef(null);
  const teamDropdownRef = useRef(null);

  const toggleRoundDropdown = () => setRoundDropdownOpen(!roundDropdownOpen);
  const toggleTeamDropdown = () => setTeamDropdownOpen(!teamDropdownOpen);

  const handleRoundSelect = (round) => {
    setSelectedRound(round);
    setRoundDropdownOpen(false);
  };

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
    setTeamDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      roundDropdownRef.current &&
      !roundDropdownRef.current.contains(event.target)
    ) {
      setRoundDropdownOpen(false);
    }
    if (
      teamDropdownRef.current &&
      !teamDropdownRef.current.contains(event.target)
    ) {
      setTeamDropdownOpen(false);
    }
  };

  const handleYearChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      if (
        value === "" ||
        (parseInt(value, 10) >= 1897 && parseInt(value, 10) <= 2024) ||
        value.length < 4
      ) {
        setYear(value);
      }
    }
  };

  const handleSearch = () => {
    setSearchInitiated(true); // Set search initiated to true when search is clicked
    if (year) {
      let apiUrl = `https://api.squiggle.com.au/?q=games;year=${year}`;

      if (selectedRound !== "Select Round") {
        const roundNumber = selectedRound.split(" ")[1];
        apiUrl += `;round=${roundNumber}`;
      }

      const selectedTeamData = teams.find((team) => team.name === selectedTeam);
      if (selectedTeamData) {
        apiUrl += `;team=${selectedTeamData.id}`;
      }

      if (isIncompleteChecked) {
        apiUrl += ";complete=!100;";
      } else if (isCompleteChecked) {
        apiUrl += ";complete=100;";
      }

      if (isLiveChecked) {
        apiUrl += ";live=1;";
      }

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          let filteredGames = data.games;

          if (isFinalChecked) {
            filteredGames = filteredGames.filter((game) =>
              [1, 2, 3, 4, 5, 6].includes(game.is_final)
            );
          }

          if (isGrandFinalChecked) {
            filteredGames = filteredGames.filter(
              (game) => game.is_grand_final === 1
            );
          }

          setGamesData({ ...data, games: filteredGames });
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.matchLookupPage}>
      <h1 className={styles.matchLookupTitle}>Match Lookup</h1>
      <div className={styles.matchLookupContent}>
        <div className={styles.filterMenu}>
          <div className={styles.leftFilters}>
            <div className={styles.filterColumn}>
              <label htmlFor="yearInput">Year</label>
              <input
                type="text"
                id="yearInput"
                placeholder="Input Year"
                value={year}
                onChange={handleYearChange}
                maxLength="4"
              />
              <span className={styles.yearIndicator}>&#x2716;</span>
            </div>
            <div className={styles.filterColumn}>
              <label>Round</label>
              <div className={styles.customDropdown} ref={roundDropdownRef}>
                <div
                  className={styles.dropdownHeader}
                  onClick={toggleRoundDropdown}
                >
                  {selectedRound}
                </div>
                {roundDropdownOpen && (
                  <ul className={styles.dropdownList}>
                    <li
                      className={styles.dropdownListItem}
                      onClick={() => handleRoundSelect("Select Round")}
                    >
                      Unselect
                    </li>
                    {rounds.map((round, index) => (
                      <li
                        key={index}
                        className={styles.dropdownListItem}
                        onClick={() => handleRoundSelect(round)}
                      >
                        {round}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className={styles.filterColumn}>
              <label>Team</label>
              <div className={styles.customDropdown} ref={teamDropdownRef}>
                <div
                  className={styles.dropdownHeader}
                  onClick={toggleTeamDropdown}
                >
                  {selectedTeam}
                </div>
                {teamDropdownOpen && (
                  <ul className={styles.dropdownList}>
                    <li
                      className={styles.dropdownListItem}
                      onClick={() => handleTeamSelect("Select Team")}
                    >
                      Unselect
                    </li>
                    {teams.map((team, index) => (
                      <li
                        key={index}
                        className={styles.dropdownListItem}
                        onClick={() => handleTeamSelect(team.name)}
                      >
                        {team.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className={styles.rightFilters}>
            <div className={styles.filterCheckboxes}>
              <label>
                <input
                  type="checkbox"
                  checked={isIncompleteChecked}
                  onChange={() => setIsIncompleteChecked(!isIncompleteChecked)}
                  disabled={isCompleteChecked}
                />
                Incomplete
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={isCompleteChecked}
                  onChange={() => setIsCompleteChecked(!isCompleteChecked)}
                  disabled={isIncompleteChecked}
                />
                Complete
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={isLiveChecked}
                  onChange={() => setIsLiveChecked(!isLiveChecked)}
                />
                Live
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={isFinalChecked}
                  onChange={() => setIsFinalChecked(!isFinalChecked)}
                />
                Final
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={isGrandFinalChecked}
                  onChange={() => setIsGrandFinalChecked(!isGrandFinalChecked)}
                />
                Grand Final
              </label>
            </div>
          </div>
        </div>
        <div className={styles.searchButtonContainer}>
          <button className={styles.searchButton} onClick={handleSearch}>
            Search
          </button>
        </div>
        <div className={styles.resultsContainer}>
          {searchInitiated && !gamesData ? (
            <p className={styles.loadingText}>Loading match data...</p>
          ) : gamesData && gamesData.games.length > 0 ? (
            gamesData.games.map((game, index) => {
              const date = new Date(game.date);
              const day = date.getDate();
              const month = date.toLocaleString("en-GB", { month: "short" });
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const ampm = hours >= 12 ? "PM" : "AM";
              const formattedHours = hours % 12 || 12;
              const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

              return (
                <div key={index} className={styles.gameDetails}>
                  <div className={styles.roundYearRow}>
                    <div className={styles.roundname}>{game.roundname}</div>
                    <div className={styles.year}>{game.year}</div>
                  </div>
                  <div className={styles.winnerRow}>
                    <div className={styles.winnerLabel}>Winner:</div>
                    <div className={styles.winner}>{game.winner || "Draw"}</div>
                  </div>
                  <div className={styles.venueDateRow}>
                    <div className={styles.venue}>{game.venue}</div>
                    <div className={styles.date}>
                      {day} {month} {formattedHours}:{formattedMinutes} {ampm}
                    </div>
                  </div>

                  <div className={styles.teamRow}>
                    <div className={styles.teamName}>
                      <img
                        src={teamLogos[game.hteam]}
                        alt={`${game.hteam} logo`}
                        className={styles.teamLogo}
                      />
                      {game.hteam}
                    </div>
                    <div className={styles.teamScore}>
                      <span>{game.hgoals}</span>.
                      <span>{game.hbehinds}&nbsp;</span> (
                      <span>{game.hscore}</span>)
                    </div>
                  </div>
                  <div className={styles.teamRow}>
                    <div className={styles.teamName}>
                      <img
                        src={teamLogos[game.ateam]}
                        alt={`${game.ateam} logo`}
                        className={styles.teamLogo}
                      />
                      {game.ateam}
                    </div>
                    <div className={styles.teamScore}>
                      <span>{game.agoals}</span>.
                      <span>{game.abehinds}&nbsp;</span> (
                      <span>{game.ascore}</span>)
                    </div>
                  </div>
                </div>
              );
            })
          ) : searchInitiated ? (
            <p className={styles.noDataText}>No match data available.</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MatchLookupPage;
