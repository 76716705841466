const gameData = [
  { unixtime: 1709470800, round: 0 }, // Opening Round start
  { unixtime: 1710075599, round: 0 }, // Opening Round end
  { unixtime: 1710075600, round: 1 }, // Round 1 start
  { unixtime: 1710680399, round: 1 }, // Round 1 end
  { unixtime: 1710680400, round: 2 }, // Round 2 start
  { unixtime: 1711285199, round: 2 }, // Round 2 end
  { unixtime: 1711285200, round: 3 }, // Round 3 start
  { unixtime: 1711976399, round: 3 }, // Round 3 end
  { unixtime: 1711976400, round: 4 }, // Round 4 start
  { unixtime: 1712498399, round: 4 }, // Round 4 end
  { unixtime: 1712498400, round: 5 }, // Round 5 start
  { unixtime: 1713103199, round: 5 }, // Round 5 end
  { unixtime: 1713103200, round: 6 }, // Round 6 start
  { unixtime: 1713707999, round: 6 }, // Round 6 end
  { unixtime: 1713708000, round: 7 }, // Round 7 start
  { unixtime: 1714312799, round: 7 }, // Round 7 end
  { unixtime: 1714312800, round: 8 }, // Round 8 start
  { unixtime: 1714917599, round: 8 }, // Round 8 end
  { unixtime: 1714917600, round: 9 }, // Round 9 start
  { unixtime: 1715522399, round: 9 }, // Round 9 end
  { unixtime: 1715522400, round: 10 }, // Round 10 start
  { unixtime: 1716127199, round: 10 }, // Round 10 end
  { unixtime: 1716127200, round: 11 }, // Round 11 start
  { unixtime: 1716731999, round: 11 }, // Round 11 end
  { unixtime: 1716732000, round: 12 }, // Round 12 start
  { unixtime: 1717336799, round: 12 }, // Round 12 end
  { unixtime: 1717336800, round: 13 }, // Round 13 start
  { unixtime: 1718027999, round: 13 }, // Round 13 end
  { unixtime: 1718028000, round: 14 }, // Round 14 start
  { unixtime: 1718546399, round: 14 }, // Round 14 end
  { unixtime: 1718546400, round: 15 }, // Round 15 start
  { unixtime: 1719151199, round: 15 }, // Round 15 end
  { unixtime: 1719151200, round: 16 }, // Round 16 start
  { unixtime: 1719755999, round: 16 }, // Round 16 end
  { unixtime: 1719756000, round: 17 }, // Round 17 start
  { unixtime: 1720360799, round: 17 }, // Round 17 end
  { unixtime: 1720360800, round: 18 }, // Round 18 start
  { unixtime: 1720965599, round: 18 }, // Round 18 end
  { unixtime: 1720965600, round: 19 }, // Round 19 start
  { unixtime: 1721570399, round: 19 }, // Round 19 end
  { unixtime: 1721570400, round: 20 }, // Round 20 start
  { unixtime: 1722175199, round: 20 }, // Round 20 end
  { unixtime: 1722175200, round: 21 }, // Round 21 start
  { unixtime: 1722779999, round: 21 }, // Round 21 end
  { unixtime: 1722780000, round: 22 }, // Round 22 start
  { unixtime: 1723384799, round: 22 }, // Round 22 end
  { unixtime: 1723384800, round: 23 }, // Round 23 start
  { unixtime: 1723989599, round: 23 }, // Round 23 end
  { unixtime: 1723989600, round: 24 }, // Round 24 start
  { unixtime: 1724594399, round: 24 }, // Round 24 end
  { unixtime: 1724594400, round: 25 }, // Finals Week 1 start
  { unixtime: 1725803999, round: 25 }, // Finals Week 1 end
  { unixtime: 1725804000, round: 26 }, // Semi-Finals start
  { unixtime: 1726408799, round: 26 }, // Semi-Finals end
  { unixtime: 1726408800, round: 27 }, // Preliminary Finals start
  { unixtime: 1727013599, round: 27 }, // Preliminary Finals end
  { unixtime: 1727013600, round: 28 }, // Grand Final start
  { unixtime: 1727618399, round: 28 }, // Grand Final end
];

export const getCurrentRound = (unixtime) => {
  let currentRound = 0;
  for (const game of gameData) {
    if (unixtime >= game.unixtime) {
      currentRound = game.round;
    } else {
      break;
    }
  }
  return currentRound;
};
