import React, { useState, useEffect } from "react";
import styles from "./FootyLadderPage.module.css";

const FootyLadderPage = () => {
  const [ladder, setLadder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState("");

  // Define the teamNameMap within the file
  const teamNameMap = {
    "Adelaide Crows": "ADEL",
    "Brisbane Lions": "BRIS",
    "Carlton Blues": "CARL",
    "Collingwood Magpies": "COLL",
    "Essendon Bombers": "ESS",
    "Fremantle Dockers": "FREM",
    "Geelong Cats": "GEEL",
    "Gold Coast Suns": "GC",
    "GWS Giants": "GWS",
    "Hawthorn Hawks": "HAW",
    "Melbourne Demons": "MELB",
    "North Melbourne Kangaroos": "NM",
    "Port Adelaide Power": "PORT",
    "Richmond Tigers": "RICH",
    "St Kilda Saints": "STK",
    "Sydney Swans": "SYD",
    "West Coast Eagles": "WCE",
    "Western Bulldogs": "WB",
  };

  useEffect(() => {
    const cachedTeam = localStorage.getItem("selectedTeam");
    if (cachedTeam) {
      setSelectedTeam(teamNameMap[cachedTeam] || cachedTeam);
    }

    fetch("https://api.squiggle.com.au/?q=standings")
      .then((response) => response.json())
      .then((data) => {
        setLadder(data.standings);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching ladder data:", error);
        setLoading(false);
      });
  }, []);

  const getTeamDisplayName = (team) => {
    const teamNamesMap = {
      Adelaide: "ADEL",
      "Brisbane Lions": "BRIS",
      Carlton: "CARL",
      Collingwood: "COLL",
      Essendon: "ESS",
      Fremantle: "FREM",
      Geelong: "GEEL",
      "Gold Coast": "GC",
      "Greater Western Sydney": "GWS",
      Hawthorn: "HAW",
      Melbourne: "MELB",
      "North Melbourne": "NM",
      "Port Adelaide": "PORT",
      Richmond: "RICH",
      "St Kilda": "STK",
      Sydney: "SYD",
      "West Coast": "WCE",
      "Western Bulldogs": "WB",
    };
    return teamNamesMap[team] || team;
  };

  return (
    <div className={styles.footyLadderPage}>
      <h1 className={styles.footyLadderTitle}>Footy Ladder</h1>
      <div className={styles.footyLadderContent}>
        {loading ? (
          <p className={styles.loadingText}>Loading...</p>
        ) : (
          <table className={styles.ladderTable}>
            <thead>
              <tr>
                <th>#</th>
                <th>Team</th>
                <th>P</th>
                <th>Pts</th>
                <th>%</th>
                <th>W</th>
                <th>L</th>
                <th>D</th>
                <th>F</th>
                <th>A</th>
              </tr>
            </thead>
            <tbody>
              {ladder.map((team, index) => (
                <>
                  <tr key={team.id}>
                    <td>{team.rank}</td>
                    <td>
                      <span
                        className={
                          getTeamDisplayName(team.name) === selectedTeam
                            ? styles.highlightedTeam
                            : ""
                        }
                      >
                        {getTeamDisplayName(team.name)}
                      </span>
                    </td>
                    <td>{team.played}</td>
                    <td>{team.pts}</td>
                    <td>{team.percentage.toFixed(2)}</td>
                    <td>{team.wins}</td>
                    <td>{team.losses}</td>
                    <td>{team.draws}</td>
                    <td>{team.for}</td>
                    <td>{team.against}</td>
                  </tr>
                  {index === 7 && (
                    <tr className={styles.topEightRow}>
                      <td colSpan="10" className={styles.topEightText}>
                        ^ &nbsp; &nbsp; &nbsp; &nbsp; TOP EIGHT &nbsp; &nbsp;
                        &nbsp; &nbsp; ^
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default FootyLadderPage;
