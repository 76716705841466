/* HomePage JS */
import React, { useState, useEffect } from "react";
import styles from "./HomePage.module.css";
import teamLogos from "../utils/teamLogos";
import { getCurrentRound } from "../utils/roundUtils";
import whistle from "./images/sports_32.png";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";

// Map full team names to their corresponding abbreviations
const teamNameMap = {
  "Adelaide Crows": "ADEL",
  "Brisbane Lions": "BRIS",
  "Carlton Blues": "CARL",
  "Collingwood Magpies": "COLL",
  "Essendon Bombers": "ESS",
  "Fremantle Dockers": "FREM",
  "Geelong Cats": "GEEL",
  "Gold Coast Suns": "GC",
  "GWS Giants": "GWS",
  "Hawthorn Hawks": "HAW",
  "Melbourne Demons": "MELB",
  "North Melbourne Kangaroos": "NM",
  "Port Adelaide Power": "PORT",
  "Richmond Tigers": "RICH",
  "St Kilda Saints": "STK",
  "Sydney Swans": "SYD",
  "West Coast Eagles": "WCE",
  "Western Bulldogs": "WB",
};

const getTeamDisplayName = (team) => {
  const teamNamesMap = {
    Adelaide: "ADEL",
    "Brisbane Lions": "BRIS",
    Carlton: "CARL",
    Collingwood: "COLL",
    Essendon: "ESS",
    Fremantle: "FREM",
    Geelong: "GEEL",
    "Gold Coast": "GC",
    "Greater Western Sydney": "GWS",
    Hawthorn: "HAW",
    Melbourne: "MELB",
    "North Melbourne": "NM",
    "Port Adelaide": "PORT",
    Richmond: "RICH",
    "St Kilda": "STK",
    Sydney: "SYD",
    "West Coast": "WCE",
    "Western Bulldogs": "WB",
  };
  return teamNamesMap[team] || team;
};

const HomePage = () => {
  const [liveMatches, setLiveMatches] = useState([]);
  const [recentMatches, setRecentMatches] = useState([]);
  const [isLiveCollapsed, setIsLiveCollapsed] = useState(true);
  const [useMockData] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");

  useEffect(() => {
    const cachedTeam = localStorage.getItem("selectedTeam");
    if (cachedTeam) {
      setSelectedTeam(teamNameMap[cachedTeam] || cachedTeam);
    }
  }, []);

  const toggleLiveContainer = () => {
    setIsLiveCollapsed(!isLiveCollapsed);
  };

  const mockLiveGames = [
    {
      id: 1,
      hteam: "Geelong",
      ateam: "Western Bulldogs",
      hscore: 48,
      ascore: 95,
      hgoals: 7,
      hbehinds: 6,
      agoals: 14,
      abehinds: 11,
      timestr: "Q4 30:32",
      venue: "Kardinia Park",
      date: "2024-07-20T04:00:00Z",
    },
    {
      id: 2,
      hteam: "Port Adelaide",
      ateam: "Richmond",
      hscore: 115,
      ascore: 75,
      hgoals: 17,
      hbehinds: 13,
      agoals: 11,
      abehinds: 9,
      timestr: "Q4 28:51",
      venue: "Adelaide Oval",
      date: "2024-07-20T07:00:00Z",
    },
  ];

  const mockRecentGames = [
    {
      id: 5,
      hteam: "Collingwood",
      ateam: "Melbourne",
      hscore: 102,
      ascore: 95,
      hgoals: 15,
      hbehinds: 12,
      agoals: 14,
      abehinds: 11,
      timestr: "Full Time",
      venue: "MCG",
      date: "2024-07-19T04:00:00Z",
      round: getCurrentRound(Math.floor(Date.now() / 1000)),
    },
    {
      id: 6,
      hteam: "Gold Coast",
      ateam: "West Coast",
      hscore: 91,
      ascore: 82,
      hgoals: 13,
      hbehinds: 13,
      agoals: 12,
      abehinds: 10,
      timestr: "Full Time",
      venue: "Metricon Stadium",
      date: "2024-07-19T07:00:00Z",
      round: getCurrentRound(Math.floor(Date.now() / 1000)),
    },
  ];

  useEffect(() => {
    const fetchLiveMatches = async () => {
      try {
        const response = await fetch(
          "https://api.squiggle.com.au/?q=games;live=1"
        );
        const data = await response.json();
        setLiveMatches(data.games);

        if (data.games.length > 0) {
          setIsLiveCollapsed(false);
        } else {
          setIsLiveCollapsed(true);
        }
      } catch (error) {
        console.error("Error fetching live matches:", error);
      }
    };

    const fetchRecentMatches = async () => {
      try {
        const response = await fetch(
          "https://api.squiggle.com.au/?q=games;year=2024;complete=100"
        );
        const data = await response.json();
        const now = Math.floor(Date.now() / 1000);
        const currentRound = getCurrentRound(now);
        const recentGames = data.games
          .filter(
            (game) =>
              game.round === currentRound - 1 || game.round === currentRound
          )
          .slice(-9)
          .reverse();
        setRecentMatches(recentGames);
      } catch (error) {
        console.error("Error fetching recent matches:", error);
      }
    };

    if (useMockData) {
      setLiveMatches(mockLiveGames);
      setRecentMatches(mockRecentGames.reverse());

      if (mockLiveGames.length > 0) {
        setIsLiveCollapsed(false);
      }
    } else {
      fetchLiveMatches();
      fetchRecentMatches();
    }
  }, [useMockData]);

  return (
    <div className={styles.homePage}>
      <div className={styles.liveMatches}>
        <h1 className={styles.liveTitle} onClick={toggleLiveContainer}>
          Live Matches {isLiveCollapsed ? "▼" : "▲"}
        </h1>
        {isLiveCollapsed && <div className={styles.collapsedLine}></div>}
        {!isLiveCollapsed && (
          <div className={styles.liveContent}>
            {liveMatches.length > 0 ? (
              <div className={styles.liveMatchesContainer}>
                {liveMatches.map((match) => {
                  const localDate = toZonedTime(
                    new Date(match.date),
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  );
                  const formattedDate = format(localDate, "EEE MMM dd");
                  const formattedTime = format(localDate, "h:mm a");
                  return (
                    <div key={match.id} className={styles.matchCard}>
                      <div className={styles.matchDateTime}>
                        <span className={styles.matchTime}>
                          {formattedTime}
                        </span>
                        <span className={styles.matchDate}>
                          {formattedDate}
                        </span>
                      </div>
                      <div className={styles.matchRow}>
                        <div
                          className={`${styles.team} ${
                            getTeamDisplayName(match.hteam) === selectedTeam
                              ? styles.highlightedTeam
                              : ""
                          }`}
                        >
                          <img
                            src={teamLogos[match.hteam]}
                            alt={`${match.hteam} logo`}
                            className={styles.teamLogo}
                          />
                          <span className={styles.teamName}>
                            {getTeamDisplayName(match.hteam)}
                          </span>
                        </div>
                        <div className={styles.teamScores}>
                          <span className={styles.teamGoals}>
                            ({match.hgoals || 0}.{match.hbehinds || 0})
                          </span>
                          <span className={styles.teamScore}>
                            {match.hscore}
                          </span>
                        </div>
                      </div>
                      <div className={styles.matchRow}>
                        <div
                          className={`${styles.team} ${
                            getTeamDisplayName(match.ateam) === selectedTeam
                              ? styles.highlightedTeam
                              : ""
                          }`}
                        >
                          <img
                            src={teamLogos[match.ateam]}
                            alt={`${match.ateam} logo`}
                            className={styles.teamLogo}
                          />
                          <span className={styles.teamName}>
                            {getTeamDisplayName(match.ateam)}
                          </span>
                        </div>
                        <div className={styles.teamScores}>
                          <span className={styles.teamGoals}>
                            ({match.agoals || 0}.{match.abehinds || 0})
                          </span>
                          <span className={styles.teamScore}>
                            {match.ascore}
                          </span>
                        </div>
                      </div>
                      <div className={styles.matchDetails}>
                        <span>{match.timestr}</span>
                        <span>{match.venue}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={styles.noLiveContainer}>
                <img className={styles.whistle} src={whistle} alt="Whistle" />
                <p className={styles.noLiveAlert}>
                  No live match data available
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.recentMatches}>
        <h1 className={styles.recentTitle}>Recent Matches</h1>
        <div className={styles.recentContent}>
          {recentMatches.length > 0 ? (
            <div className={styles.recentMatchesContainer}>
              {recentMatches.map((match) => {
                const localDate = toZonedTime(
                  new Date(match.date),
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                );
                const formattedDate = format(localDate, "EEE MMM dd");
                const formattedTime = format(localDate, "h:mm a");
                return (
                  <div key={match.id} className={styles.matchCard}>
                    <div className={styles.matchDateTime}>
                      <span className={styles.matchTime}>{formattedTime}</span>
                      <span className={styles.matchDate}>{formattedDate}</span>
                    </div>
                    <div className={styles.matchRow}>
                      <div
                        className={`${styles.team} ${
                          getTeamDisplayName(match.hteam) === selectedTeam
                            ? styles.highlightedTeam
                            : ""
                        }`}
                      >
                        <img
                          src={teamLogos[match.hteam]}
                          alt={`${match.hteam} logo`}
                          className={styles.teamLogo}
                        />
                        <span className={styles.teamName}>
                          {getTeamDisplayName(match.hteam)}
                        </span>
                      </div>
                      <div className={styles.teamScores}>
                        <span className={styles.teamGoals}>
                          ({match.hgoals || 0}.{match.hbehinds || 0})
                        </span>
                        <span className={styles.teamScore}>{match.hscore}</span>
                      </div>
                    </div>
                    <div className={styles.matchRow}>
                      <div
                        className={`${styles.team} ${
                          getTeamDisplayName(match.ateam) === selectedTeam
                            ? styles.highlightedTeam
                            : ""
                        }`}
                      >
                        <img
                          src={teamLogos[match.ateam]}
                          alt={`${match.ateam} logo`}
                          className={styles.teamLogo}
                        />
                        <span className={styles.teamName}>
                          {getTeamDisplayName(match.ateam)}
                        </span>
                      </div>
                      <div className={styles.teamScores}>
                        <span className={styles.teamGoals}>
                          ({match.agoals || 0}.{match.abehinds || 0})
                        </span>
                        <span className={styles.teamScore}>{match.ascore}</span>
                      </div>
                    </div>
                    <div className={styles.matchDetails}>
                      <span>{match.timestr}</span>
                      <span>{match.venue}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles.noRecentContainer}>
              <img className={styles.whistle} src={whistle} alt="Whistle" />
              <p className={styles.noRecentAlert}>
                No recent match data available
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
