/* Navbar JS */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Navbar.module.css";
import homeIcon from "./images/home_32.png";
import currentIcon from "./images/view_carousel_32.png";
import allroundsIcon from "./images/calendar_month_32.png";
import ladderIcon from "./images/data_table_32.png";
import searchIcon from "./images/manage_search_32.png";

const Navbar = () => {
  const location = useLocation();

  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>
        <li className={styles.navItem}>
          <Link
            to="/home"
            className={
              location.pathname === "/home"
                ? styles.navLinkActive
                : styles.navLink
            }
          >
            <img src={homeIcon} alt="Home" className={styles.icon} />
            <span className={styles.label}>Home</span>
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link
            to="/current-round"
            className={
              location.pathname === "/current-round"
                ? styles.navLinkActive
                : styles.navLink
            }
          >
            <img
              src={currentIcon}
              alt="Current Round"
              className={styles.icon}
            />
            <span className={styles.label}>This Week</span>
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link
            to="/all-rounds"
            className={
              location.pathname === "/all-rounds"
                ? styles.navLinkActive
                : styles.navLink
            }
          >
            <img src={allroundsIcon} alt="All Rounds" className={styles.icon} />
            <span className={styles.label}>Fixture</span>
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link
            to="/footy-ladder"
            className={
              location.pathname === "/footy-ladder"
                ? styles.navLinkActive
                : styles.navLink
            }
          >
            <img src={ladderIcon} alt="Footy Ladder" className={styles.icon} />
            <span className={styles.label}>Footy Ladder</span>
          </Link>
        </li>
        <li className={styles.navItem}>
          <Link
            to="/match-lookup"
            className={
              location.pathname === "/match-lookup"
                ? styles.navLinkActive
                : styles.navLink
            }
          >
            <img src={searchIcon} alt="Match Lookup" className={styles.icon} />
            <span className={styles.label}>Match Lookup</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
