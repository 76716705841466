/* Footer JS */
import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p className={styles.footerTrademarks}>
        All AFL and AFL club names, logos, and other trademarks are the property
        of the AFL and the respective AFL clubs. This website is not affiliated
        with or endorsed by the AFL or any AFL club.
      </p>

      <p className={styles.footerCreated}>
        © 2024 - created by&nbsp;<b className={styles.footerName}>jswil</b>
      </p>
    </footer>
  );
};

export default Footer;
