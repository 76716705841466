/* All Rounds JS */
import React, { useState } from "react";
import styles from "./AllRoundsPage.module.css";
import teamLogos from "../utils/teamLogos";
import headToHead from "./images/solid-head-to-head-24.png";
import placeholderLogo from "./images/logo-placeholder-22.png";

const getTeamDisplayName = (team) => {
  const teamNamesMap = {
    Adelaide: "Adelaide",
    "Brisbane Lions": "Brisbane",
    Carlton: "Carlton",
    Collingwood: "Collingwood",
    Essendon: "Essendon",
    Fremantle: "Fremantle",
    Geelong: "Geelong",
    "Gold Coast": "Gold Coast",
    "Greater Western Sydney": "Giants",
    Hawthorn: "Hawthorn",
    Melbourne: "Melbourne",
    "North Melbourne": "North Melb",
    "Port Adelaide": "Port Adelaide",
    Richmond: "Richmond",
    "St Kilda": "Saint Kilda",
    Sydney: "Sydney",
    "West Coast": "West Coast",
    "Western Bulldogs": "Bulldogs",
  };
  return teamNamesMap[team] || team;
};

const AllRoundsPage = () => {
  const [matches, setMatches] = useState([]);
  const [selectedRound, setSelectedRound] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleRoundSelection = async (roundNumber) => {
    setSelectedRound(roundNumber);
    setIsDropdownOpen(false);
    const url = `https://api.squiggle.com.au/?q=games;year=2024;round=${roundNumber}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setMatches(data.games || []);
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const getDayAndFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
    });
    return { dayName, formattedDate };
  };

  const formatMatchTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const groupMatchesByDay = (matches) => {
    return matches.reduce((acc, match) => {
      const { dayName, formattedDate } = getDayAndFormattedDate(match.date);
      if (!acc[dayName]) acc[dayName] = { formattedDate, matches: [] };
      acc[dayName].matches.push(match);
      return acc;
    }, {});
  };

  const groupedMatches = groupMatchesByDay(matches);

  return (
    <div className={styles.allRoundsPage}>
      <h1 className={styles.allRoundsTitle}>2024 AFL Season</h1>
      <div className={styles.allRoundsContent}>
        <div className={styles.allRoundsDropdown}>
          <button
            className={styles.dropbtn}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
          >
            Choose a round
          </button>
          {isDropdownOpen && (
            <div className={styles.dropdownContent}>
              <div className={styles.dropdownColumn}>
                <h3 className={styles.dropdownHeading}>REGULAR SEASON</h3>
                <ul className={styles.dropdownList}>
                  <li
                    onClick={() => handleRoundSelection(0)}
                    className={styles.dropdownItem}
                  >
                    Opening Round
                  </li>
                  <li
                    onClick={() => handleRoundSelection(1)}
                    className={styles.dropdownItem}
                  >
                    Round 1
                  </li>
                  <li
                    onClick={() => handleRoundSelection(2)}
                    className={styles.dropdownItem}
                  >
                    Round 2
                  </li>
                  <li
                    onClick={() => handleRoundSelection(3)}
                    className={styles.dropdownItem}
                  >
                    Round 3
                  </li>
                  <li
                    onClick={() => handleRoundSelection(4)}
                    className={styles.dropdownItem}
                  >
                    Round 4
                  </li>
                  <li
                    onClick={() => handleRoundSelection(5)}
                    className={styles.dropdownItem}
                  >
                    Round 5
                  </li>
                  <li
                    onClick={() => handleRoundSelection(6)}
                    className={styles.dropdownItem}
                  >
                    Round 6
                  </li>
                  <li
                    onClick={() => handleRoundSelection(7)}
                    className={styles.dropdownItem}
                  >
                    Round 7
                  </li>
                  <li
                    onClick={() => handleRoundSelection(8)}
                    className={styles.dropdownItem}
                  >
                    Round 8
                  </li>
                  <li
                    onClick={() => handleRoundSelection(9)}
                    className={styles.dropdownItem}
                  >
                    Round 9
                  </li>
                  <li
                    onClick={() => handleRoundSelection(10)}
                    className={styles.dropdownItem}
                  >
                    Round 10
                  </li>
                  <li
                    onClick={() => handleRoundSelection(11)}
                    className={styles.dropdownItem}
                  >
                    Round 11
                  </li>
                  <li
                    onClick={() => handleRoundSelection(12)}
                    className={styles.dropdownItem}
                  >
                    Round 12
                  </li>
                  <li
                    onClick={() => handleRoundSelection(13)}
                    className={styles.dropdownItem}
                  >
                    Round 13
                  </li>
                  <li
                    onClick={() => handleRoundSelection(14)}
                    className={styles.dropdownItem}
                  >
                    Round 14
                  </li>
                  <li
                    onClick={() => handleRoundSelection(15)}
                    className={styles.dropdownItem}
                  >
                    Round 15
                  </li>
                  <li
                    onClick={() => handleRoundSelection(16)}
                    className={styles.dropdownItem}
                  >
                    Round 16
                  </li>
                  <li
                    onClick={() => handleRoundSelection(17)}
                    className={styles.dropdownItem}
                  >
                    Round 17
                  </li>
                  <li
                    onClick={() => handleRoundSelection(18)}
                    className={styles.dropdownItem}
                  >
                    Round 18
                  </li>
                  <li
                    onClick={() => handleRoundSelection(19)}
                    className={styles.dropdownItem}
                  >
                    Round 19
                  </li>
                  <li
                    onClick={() => handleRoundSelection(20)}
                    className={styles.dropdownItem}
                  >
                    Round 20
                  </li>
                  <li
                    onClick={() => handleRoundSelection(21)}
                    className={styles.dropdownItem}
                  >
                    Round 21
                  </li>
                  <li
                    onClick={() => handleRoundSelection(22)}
                    className={styles.dropdownItem}
                  >
                    Round 22
                  </li>
                  <li
                    onClick={() => handleRoundSelection(23)}
                    className={styles.dropdownItem}
                  >
                    Round 23
                  </li>
                  <li
                    onClick={() => handleRoundSelection(24)}
                    className={styles.dropdownItem}
                  >
                    Round 24
                  </li>
                </ul>
              </div>
              <div className={styles.dropdownColumn}>
                <h3 className={styles.dropdownHeading}>FINALS</h3>
                <ul className={styles.dropdownList}>
                  <li
                    onClick={() => handleRoundSelection(25)}
                    className={styles.dropdownItem}
                  >
                    Qual & Elim Finals
                  </li>
                  <li
                    onClick={() => handleRoundSelection(26)}
                    className={styles.dropdownItem}
                  >
                    Semi Finals
                  </li>
                  <li
                    onClick={() => handleRoundSelection(27)}
                    className={styles.dropdownItem}
                  >
                    Preliminary Finals
                  </li>
                  <li
                    onClick={() => handleRoundSelection(28)}
                    className={styles.dropdownItem}
                  >
                    Grand Final
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        {selectedRound !== null && (
          <div className={styles.matchResultsContainer}>
            <div className={styles.matchResults}>
              <h2 className={styles.selectedRoundTitle}>
                {selectedRound === 0
                  ? "Opening Round"
                  : selectedRound === 25
                  ? "Qual & Elim Finals"
                  : selectedRound === 26
                  ? "Semi Finals"
                  : selectedRound === 27
                  ? "Preliminary Finals"
                  : selectedRound === 28
                  ? "Grand Final"
                  : `Round ${selectedRound}`}
              </h2>
              {Object.keys(groupedMatches).map((dayName, index) => (
                <div key={index} className={styles.matchDayGroup}>
                  <div className={styles.matchDayHeader}>
                    <span>{dayName}</span>
                    <span className={styles.matchDate}>
                      {groupedMatches[dayName].formattedDate}
                    </span>
                  </div>
                  {groupedMatches[dayName].matches.map((match, matchIndex) => (
                    <div key={matchIndex} className={styles.matchCard}>
                      <div className={styles.teamNamesRow}>
                        <span className={styles.teamNameHome}>
                          {getTeamDisplayName(match.hteam)}
                        </span>
                        <span className={styles.teamNameAway}>
                          {getTeamDisplayName(match.ateam)}
                        </span>
                      </div>
                      <div className={styles.matchDetails}>
                        <div className={styles.matchTime}>
                          {formatMatchTime(match.date)}
                        </div>
                        <div className={styles.teamHome}>
                          <img
                            src={teamLogos[match.hteam] || placeholderLogo}
                            alt={`${match.hteam} logo`}
                            className={styles.teamLogoHome}
                          />
                          <span className={styles.teamScoreHome}>
                            {match.hscore}
                          </span>
                        </div>
                        <div className={styles.vs}>
                          <img src={headToHead} alt="Head to Head" />
                        </div>
                        <div className={styles.teamAway}>
                          <span className={styles.teamScoreAway}>
                            {match.ascore}
                          </span>
                          <img
                            src={teamLogos[match.ateam] || placeholderLogo}
                            alt={`${match.ateam} logo`}
                            className={styles.teamLogoAway}
                          />
                        </div>
                        <div className={styles.matchStatus}>
                          {match.timestr}
                        </div>
                      </div>
                      <div className={styles.venueRow}>
                        <span className={styles.venue}>{match.venue}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllRoundsPage;
