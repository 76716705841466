import React from "react";
import styles from "./AboutPage.module.css";

const AboutPage = () => {
  return (
    <div className={styles.aboutPage}>
      <h1 className={styles.aboutTitle}>About</h1>
      <div className={styles.aboutContent}>{/* About content goes here */}</div>
    </div>
  );
};

export default AboutPage;
