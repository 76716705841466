import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import WelcomePage from "./pages/WelcomePage";
import HomePage from "./pages/HomePage";
import CurrentRoundPage from "./pages/CurrentRoundPage";
import AllRoundsPage from "./pages/AllRoundsPage";
import FootyLadderPage from "./pages/FootyLadderPage";
import MatchLookupPage from "./pages/MatchLookupPage";
import AboutPage from "./pages/AboutPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./App.css";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/current-round" element={<CurrentRoundPage />} />
          <Route path="/all-rounds" element={<AllRoundsPage />} />
          <Route path="/footy-ladder" element={<FootyLadderPage />} />
          <Route path="/match-lookup" element={<MatchLookupPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

const Layout = ({ children }) => {
  const location = useLocation();

  // Determine whether to hide the header and footer (Welcome Page)
  const hideHeaderFooter = location.pathname === "/";

  // Determine whether to hide only the footer (Current Round Page)
  const hideFooter =
    location.pathname === "/current-round" || location.pathname === "/home";

  return (
    <>
      {!hideHeaderFooter && <Header />}
      {!hideHeaderFooter && <Navbar />}
      {children}
      {!hideHeaderFooter && !hideFooter && <Footer />}
    </>
  );
};

export default App;
