/* WelcomePage JS */
import React, { useState, useEffect } from "react";
import styles from "./WelcomePage.module.css";
import footyLogo from "./images/45deg-red-football-144.png";
import messageImage1 from "./images/sports_score_32.png";
import messageImage2 from "./images/calendar_month_32.png";
import messageImage3 from "./images/data_table_32.png";

const WelcomePage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 5000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleContinueClick = () => {
    setTransition(true);
    setTimeout(() => {
      window.location.href = "/home";
    }, 300); // Duration of the transition
  };

  const scrollToIndex = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div
      className={`${styles.welcomePage} ${transition ? styles.fadeOut : ""}`}
    >
      <div className={styles.created}>
        <p className={styles.createdBy}>
          created by <b>jswil</b>
        </p>
      </div>
      <div className={styles.content}>
        <img className={styles.welcomeImage} src={footyLogo} alt="Footy Logo" />
        <h3 className={styles.welcomeMessage1}>welcome to</h3>
        <h1 className={styles.welcomeMessage2}>footy rounds</h1>
      </div>
      <div className={styles.scrollContainer}>
        <div
          className={styles.scrollContent}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          <div className={styles.scrollItem}>
            <img
              src={messageImage1}
              alt="Message 1"
              className={styles.scrollImage}
            />
            <p className={styles.scrollText}>stay updated with live scores</p>
          </div>
          <div className={styles.scrollItem}>
            <img
              src={messageImage2}
              alt="Message 2"
              className={styles.scrollImage}
            />
            <p className={styles.scrollText}>
              check out the latest match fixtures
            </p>
          </div>
          <div className={styles.scrollItem}>
            <img
              src={messageImage3}
              alt="Message 3"
              className={styles.scrollImage}
            />
            <p className={styles.scrollText}>see who's on top of the ladder</p>
          </div>
        </div>
        <div className={styles.dots}>
          {[0, 1, 2].map((index) => (
            <span
              key={index}
              className={`${styles.dot} ${
                currentIndex === index ? styles.active : ""
              }`}
              onClick={() => scrollToIndex(index)}
            />
          ))}
        </div>
      </div>
      <button onClick={handleContinueClick} className={styles.continueButton}>
        click to continue
      </button>
    </div>
  );
};

export default WelcomePage;
