const teamLogos = {
  Richmond:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/yFuuepr3VPLTdUlAcGu19w_48x48.png",
  "Western Bulldogs":
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/vTGkdxIs85r51D4BtDGTBA_48x48.png",
  "St Kilda":
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/6OEduvyEYzhnlaQuTc9kZw_48x48.png",
  Geelong:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/-_2uXXx3DzcukKfOAdrmiQ_48x48.png",
  "West Coast":
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/Gd7qwrC6GyGiV80Chvc-6A_48x48.png",
  Adelaide:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/SIOkdbHI79Q4Hs2ccc2Vbw_48x48.png",
  "Port Adelaide":
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/J4ivi_1Z5CZfMfVicP0ndQ_48x48.png",
  Carlton:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/ju3kBzuvESuAK-2aiIIGHA_48x48.png",
  "Brisbane Lions":
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/DfoYohd9NIHcTS0kP9b3XQ_48x48.png",
  Collingwood:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/XBCB1e6_KdvJq3yC1KVW8Q_48x48.png",
  Essendon:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/zjGLme0ZN0UBkpe4zihuTw_48x48.png",
  Sydney:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/oJ5IsRTkEykxHlg9Yfo3-A_48x48.png",
  "Greater Western Sydney":
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/bFnvzxN2TNblAlWdJ0zf1g_48x48.png",
  Melbourne:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/2AJ8NZxeHX8Yb4VS99Hebw_48x48.png",
  Hawthorn:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/-IyWetEyyBbnbCd7TMyFPQ_48x48.png",
  Fremantle:
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/XptONx2JcevS7ms94Rkodg_48x48.png",
  "Gold Coast":
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/pgvHLONsXBcxHeyRPPghmQ_48x48.png",
  "North Melbourne":
    "https://ssl.gstatic.com/onebox/media/sports/logos/afl/i8kDQX7REIkyyK4PZDtflQ_48x48.png",
};

export default teamLogos;
