/* Header JS */
import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import footyLogo from "./images/45deg-red-football-144.png";
import settingsLogo from "./images/settings-100.png";

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");

  // Load the selected team from localStorage when the component mounts
  useEffect(() => {
    const cachedTeam = localStorage.getItem("selectedTeam");
    if (cachedTeam) {
      setSelectedTeam(cachedTeam);
    }
  }, []);

  const handleSettingsClick = () => {
    setShowModal(!showModal);
  };

  const handleTeamSelect = (event) => {
    const team = event.target.value;
    setSelectedTeam(team);
    localStorage.setItem("selectedTeam", team); // Cache the selected team
    setShowModal(false); // Close the modal
    window.location.reload(); // Refresh the page
  };

  return (
    <header className={styles.header}>
      <img className={styles.headerImg} src={footyLogo} alt="Footy Logo" />
      <div className={styles.headerContent}>
        <h1 className={styles.h1}>footy rounds</h1>
        <img
          className={styles.settingsImg}
          src={settingsLogo}
          alt="Settings"
          onClick={handleSettingsClick}
        />
      </div>
      {showModal && (
        <div className={styles.modal}>
          <h2>SELECT YOUR TEAM</h2>
          <select
            className={styles.dropdown}
            value={selectedTeam}
            onChange={handleTeamSelect}
          >
            <option value="no-team">No Preferred Team</option>
            <option value="ADEL">Adelaide Crows</option>
            <option value="BRIS">Brisbane Lions</option>
            <option value="CARL">Carlton Blues</option>
            <option value="COLL">Collingwood Magpies</option>
            <option value="ESS">Essendon Bombers</option>
            <option value="FREM">Fremantle Dockers</option>
            <option value="GEEL">Geelong Cats</option>
            <option value="GC">Gold Coast Suns</option>
            <option value="GWS">GWS Giants</option>
            <option value="HAW">Hawthorn Hawks</option>
            <option value="MELB">Melbourne Demons</option>
            <option value="NM">North Melbourne Kangaroos</option>
            <option value="PORT">Port Adelaide Power</option>
            <option value="RICH">Richmond Tigers</option>
            <option value="STK">St Kilda Saints</option>
            <option value="SYD">Sydney Swans</option>
            <option value="WCE">West Coast Eagles</option>
            <option value="WB">Western Bulldogs</option>
          </select>
        </div>
      )}
    </header>
  );
};

export default Header;
