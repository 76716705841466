/* Current Round JS */
import React, { useState, useEffect } from "react";
import styles from "./CurrentRoundPage.module.css";
import teamLogos from "../utils/teamLogos";
import { getCurrentRound } from "../utils/roundUtils";
import whistle from "./images/sports_32.png";
import {
  format,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInDays,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

// Map full team names to their corresponding abbreviations
const teamNameMap = {
  "Adelaide Crows": "ADEL",
  "Brisbane Lions": "BRIS",
  "Carlton Blues": "CARL",
  "Collingwood Magpies": "COLL",
  "Essendon Bombers": "ESS",
  "Fremantle Dockers": "FREM",
  "Geelong Cats": "GEEL",
  "Gold Coast Suns": "GC",
  "GWS Giants": "GWS",
  "Hawthorn Hawks": "HAW",
  "Melbourne Demons": "MELB",
  "North Melbourne Kangaroos": "NM",
  "Port Adelaide Power": "PORT",
  "Richmond Tigers": "RICH",
  "St Kilda Saints": "STK",
  "Sydney Swans": "SYD",
  "West Coast Eagles": "WCE",
  "Western Bulldogs": "WB",
};

const getTeamDisplayName = (team) => {
  const teamNamesMap = {
    Adelaide: "ADEL",
    "Brisbane Lions": "BRIS",
    Carlton: "CARL",
    Collingwood: "COLL",
    Essendon: "ESS",
    Fremantle: "FREM",
    Geelong: "GEEL",
    "Gold Coast": "GC",
    "Greater Western Sydney": "GWS",
    Hawthorn: "HAW",
    Melbourne: "MELB",
    "North Melbourne": "NM",
    "Port Adelaide": "PORT",
    Richmond: "RICH",
    "St Kilda": "STK",
    Sydney: "SYD",
    "West Coast": "WCE",
    "Western Bulldogs": "WB",
  };
  return teamNamesMap[team] || team;
};

const CurrentRoundPage = () => {
  const [currentMatches, setCurrentMatches] = useState([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [useMockData] = useState(false); // Toggle for mock data
  const [selectedTeam, setSelectedTeam] = useState("");

  useEffect(() => {
    const cachedTeam = localStorage.getItem("selectedTeam");
    if (cachedTeam) {
      setSelectedTeam(teamNameMap[cachedTeam] || cachedTeam);
    }
  }, []);

  const mockCurrentGames = [
    {
      id: 1,
      hteam: "Geelong",
      ateam: "Western Bulldogs",
      hscore: 48,
      ascore: 95,
      timestr: "Q4 30:32",
      venue: "Kardinia Park",
      hgoals: 7,
      hbehinds: 6,
      agoals: 14,
      abehinds: 11,
      date: "2024-07-20T04:00:00Z",
    },
    {
      id: 2,
      hteam: "Port Adelaide",
      ateam: "Richmond",
      hscore: 115,
      ascore: 75,
      timestr: "Q4 28:51",
      venue: "Adelaide Oval",
      hgoals: 17,
      hbehinds: 13,
      agoals: 11,
      abehinds: 9,
      date: "2024-07-20T07:00:00Z",
    },
    // Additional mock data here
  ];

  useEffect(() => {
    const fetchCurrentMatches = async () => {
      try {
        const now = Math.floor(Date.now() / 1000);
        const round = getCurrentRound(now);
        setCurrentRound(round);
        const response = await fetch(
          `https://api.squiggle.com.au/?q=games;year=2024;round=${round}`
        );
        const data = await response.json();
        setCurrentMatches(data.games);
      } catch (error) {
        console.error("Error fetching current matches:", error);
      }
    };

    if (useMockData) {
      setCurrentMatches(mockCurrentGames);
      setCurrentRound(getCurrentRound(Math.floor(Date.now() / 1000)));
    } else {
      fetchCurrentMatches();
    }
  }, [useMockData]);

  const calculateRemainingTime = (date) => {
    const now = new Date();
    const gameDate = new Date(date);
    const secondsDiff = differenceInSeconds(gameDate, now);

    if (secondsDiff <= 0) {
      return null; // Game has started
    }

    const daysDiff = differenceInDays(gameDate, now);
    const hoursDiff = differenceInHours(gameDate, now) % 24;
    const minutesDiff = differenceInMinutes(gameDate, now) % 60;

    if (daysDiff > 1) {
      return `In ${daysDiff} days`;
    } else if (daysDiff === 1) {
      return `In ${daysDiff} day`;
    } else {
      return `In ${hoursDiff}h ${minutesDiff}m`;
    }
  };

  return (
    <div className={styles.currentRoundPage}>
      <h1 className={styles.currentRoundTitle}>Round {currentRound}</h1>
      <div className={styles.currentRoundContent}>
        {currentMatches.length > 0 ? (
          <div className={styles.matchCardsContainer}>
            {currentMatches.map((match) => {
              const localDate = toZonedTime(
                new Date(match.date),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              );
              const formattedDate = format(localDate, "EEE MMM dd");
              const formattedTime = format(localDate, "h:mm a");
              const remainingTime = match.remainingTime
                ? match.remainingTime
                : calculateRemainingTime(match.date);

              return (
                <div key={match.id} className={styles.matchCard}>
                  <div className={styles.matchDateTime}>
                    <span className={styles.matchTime}>{formattedTime}</span>
                    <span className={styles.matchDate}>{formattedDate}</span>
                  </div>
                  <div className={styles.matchRow}>
                    <div
                      className={`${styles.teamInfo} ${
                        getTeamDisplayName(match.hteam) === selectedTeam
                          ? styles.highlightedTeam
                          : ""
                      }`}
                    >
                      <img
                        src={teamLogos[match.hteam]}
                        alt={`${match.hteam} logo`}
                        className={styles.teamLogo}
                      />
                      <span className={styles.teamName}>
                        {getTeamDisplayName(match.hteam)}
                      </span>
                    </div>
                    <div className={styles.teamScores}>
                      <span className={styles.teamGoals}>
                        ({match.hgoals || 0}.{match.hbehinds || 0})
                      </span>
                      <span className={styles.teamScore}>{match.hscore}</span>
                    </div>
                  </div>
                  <div className={styles.matchRow}>
                    <div
                      className={`${styles.teamInfo} ${
                        getTeamDisplayName(match.ateam) === selectedTeam
                          ? styles.highlightedTeam
                          : ""
                      }`}
                    >
                      <img
                        src={teamLogos[match.ateam]}
                        alt={`${match.ateam} logo`}
                        className={styles.teamLogo}
                      />
                      <span className={styles.teamName}>
                        {getTeamDisplayName(match.ateam)}
                      </span>
                    </div>
                    <div className={styles.teamScores}>
                      <span className={styles.teamGoals}>
                        ({match.agoals || 0}.{match.abehinds || 0})
                      </span>
                      <span className={styles.teamScore}>{match.ascore}</span>
                    </div>
                  </div>
                  <div className={styles.matchDetails}>
                    <span>{remainingTime || match.timestr}</span>
                    <span>{match.venue}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.noDataContainer}>
            <img className={styles.whistle} src={whistle} alt="Whistle" />
            <p className={styles.noDataAlert}>
              No current round match data available
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentRoundPage;
